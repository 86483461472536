<script>
// import Datepicker from "vue3-datepicker";
// import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";

/*
 * Task-create component
 */
export default {
  page: {
    title: "Create Task",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    // Datepicker,
    // ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    QuillEditor,
    loaderProcessVue,
  },
  data() {
    return {
      titre: "",
      process: false,
      progress: "",
      tagList: [],
      categories: [],
      tags: [],
      isAuthError: false,
      authError: "",
      article: {
        title: "",
        content: "",
        author: "",
        categ: "",
        tag: "",
      },
      title: "Ajouter un article",
      items: [
        {
          text: "Articles",
          href: "/blog/list",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      daterange: ref(new Date()),
      editor: ClassicEditor,
      teamMember: [{ id: 1 }],
    };
  },
  methods: {
    // Add a tag to the list of tags
    addTags(event) {
      // Prevent the default form submission behavior
      event.preventDefault();

      // Get the value of the input and trim any whitespace
      const val = event.target.value.trim();

      // If the input has a value and the tag is not already in the list
      if (val.length > 0 && !this.tags.includes(val)) {
        // Add the tag to the list
        this.tags.push(val);
        // Clear the input field
        event.target.value = "";
      }
    },

    // Remove a tag from the list of tags
    removeTags(index) {
      // Remove the tag at the specified index
      this.tags.splice(index, 1);
    },

    // Remove the last tag if the input field is empty
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        // Remove the last tag from the list
        this.removeTags(this.tags.length - 1);
      }
    },
    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; 
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
    },


    // This function is called when the user clicks the upload button
    createArticle() {
      if (
        !this.article.categ ||
        !this.tags ||
        !this.article.title ||
        !this.article.author ||
        !this.article.content ||
        !this.imgPaysage ||
        !this.imgSelect
      ) {
        this.isAuthError = true;
        this.authError = "Veuillez renseigner tous les champs obligatoires";
      } else {
      this.titre = "Création de l'article";
      this.progress = true;
      this.progressValue = 0;
      this.process = true;
      this.modalOk = false;
      const onUploadProgress = (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        this.progress = Math.min(progress, 98); // Limite la valeur maximale à 98
        console.log(`Upload progress: ${this.progress}% et ${progress}`);
      };

      // if (!this.programmeId) {
      Api.postFormData(
        `/actualite/rest/article/create-article?categories=${this.article.categ}&tags=${this.tags}&titre=${this.article.title}`,
        {
          autheur: this.article.author,
          content: this.article.content,
          imagepaysage: this.imgPaysage,
          imageportrait: this.imgSelect
        },
        onUploadProgress
      )
        .then(function () {
          Swal.fire("Success!", "Article enregistré", "success");
          this.progress = false;
          this.process = false;
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          Erreur.gestionErreur(error.message);
        });
      }
    },
  },
  mounted() {
    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      console.log(`Upload progress: ${this.progress}%`);
    };
    Api.get("/actualite/rest/categories/all", onUploadProgress)
      .then((res) => {
        this.categories = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/actualite/rest/tags/all", onUploadProgress)
      .then((res) => {
        this.tagList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProcessVue :progress="progress" :title="titre" :visible="process"></loaderProcessVue>
    <div style="display: flex; justify-content: space-between;">
      <div></div>
      <div>
        <button type="submit" @click="createArticle" class="btn c2play-primary">
          Enregistrer l'article
        </button>
      </div>
    </div><br>
    
    <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Ajouter un article</h4>
            <form class="outer-repeater">
              <div data-repeater-list="outer-group" class="outer">
                <div data-repeater-item class="outer">
                  <div class="row">

                    <div class="form-group col-lg-6 mb-2">
                      <label for="taskname" class="col-form-label">Titre</label>
                      <div class="col-lg-12">
                        <input id="taskname" name="taskname" type="text" class="form-control"
                          placeholder="Saisissez le titre de l'article" v-model="article.title" />
                      </div>
                    </div>


                    <div class="col-lg-6">
                      <label class="col-form-label">{{
                        $t("pages.videos.formulaire.categ.label")
                      }}</label><span style="color: red; font-size: 1.2em">*</span>
                      <div class="col-md-12">
                        <select class="form-control" v-model="article.categ">
                          <option value="">
                            {{ $t("pages.videos.formulaire.categ.placeholder") }}
                          </option>
                          <option v-for="categ in categories" :key="categ.id" :value="categ.name">
                            {{ categ.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                  </div>

                  <div class="mb-2 row">

                    <div class="col-lg-6">
                      <label class="col-form-label">{{
                        $t("pages.videos.formulaire.tags.label")
                      }}</label>
                      <div class="form-control">
                        <div v-for="(tag, index) in tags" :key="index" class="tags-input_tag c2play-primary">
                          <span @click="removeTags(index)"><i class="mdi mdi-close-thick"></i></span>
                          {{ tag }}
                        </div>
                        <select @keydown="addTags" @keydown.delete="removeLastTag" class="tags-input_text"
                          @change="addTags">
                          <option value="">
                            {{ $t("pages.videos.formulaire.tags.placeholder") }}
                          </option>
                          <option aria-placeholder="okokok" v-for="tagL in tagList" :key="tagL.id" :value="tagL.name">
                            {{ tagL.name }}
                          </option>
                        </select>
                        <!-- <input type="text" :placeholder="$t('pages.videos.formulaire.tags.placeholder')" @keydown="addTags" @keydown.delete="removeLastTag" class="tags-input_text"> -->
                      </div>
                    </div>

                    <div class="form-group col-lg-6 mb-2">
                      <label for="taskname" class="col-form-label">Auteur</label>
                      <div class="col-lg-12">
                        <input id="taskname" name="taskname" type="text" class="form-control"
                          placeholder="Saisissez le nom de l'auteur de l'article" v-model="article.author" />
                      </div>
                    </div>

                  </div>


                  <div class="row">

                    <div class="form-group col-lg-6 mb-2">
                      <label for="taskname" class="col-form-label">Image Portrait</label>
                      <div class="col-lg-12">
                        <input id="taskname" name="taskname" type="file" class="form-control"
                          placeholder="Enter Task Name..." @change="imgVideoSelect($event)" />
                      </div>
                    </div>


                    <div class="form-group col-lg-6 mb-2">
                      <label for="taskname" class="col-form-label">Image Paysage</label>
                      <div class="col-lg-12">
                        <input id="taskname" name="taskname" type="file" class="form-control"
                          placeholder="Enter Task Name..." @change="imgVideoSelectPaysage($event)" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-4">
                    <label class="col-form-label">Contenu</label>
                    <div class="col-lg-12">
                      <QuillEditor v-model:content="article.content" toolbar="full" content="html" contentType="html"
                        theme="snow" />
                      <!-- <ckeditor :editor="editor" v-model="content"></ckeditor> -->
                    </div>
                  </div><br><br>



                  <!-- <div v-html="content" class="m-5 img-fluid"></div> -->


                  <!-- <div class="form-group row mb-4">
                    <label class="col-form-label col-lg-2">Task Date</label>
                    <div class="col-lg-10">
                      <datepicker class="form-control" v-model="daterange" range append-to-body lang="en" confirm></datepicker>
                    </div>
                  </div> -->
                  <!-- 
                  <div class="inner-repeater mb-4">
                    <div class="inner form-group mb-0 row">
                      <label class="col-form-label col-lg-2">Add Team Member</label>
                      <div
                       
                        class="inner col-lg-10 ml-md-auto"
                      >
                        <div class="mb-3 row align-items-center" v-for="( member, index) in teamMember"
                        :key="member.id">
                          <div class="col-md-6">
                            <input
                              v-model="member.name"
                              type="text"
                              class="inner form-control"
                              placeholder="Enter Name..."
                            />
                          </div>
                          <div class="col-md-4">
                            <div class="mt-4 mt-md-0">
                              <input type="file" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="mt-2 mt-md-0 d-grid">
                            <input
                              type="button"
                              class="btn btn-primary btn-block inner"
                              value="Delete"
                              @click="deleteMember(index)"
                            />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-end">
                      <div class="col-lg-10">
                        <input
                          type="button"
                          class="btn btn-success inner"
                          value="Add Number"
                          @click="addMember"
                        />
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="form-group row mb-4">
                    <label for="taskbudget" class="col-form-label col-lg-2">Budget</label>
                    <div class="col-lg-10">
                      <input
                        id="taskbudget"
                        name="taskbudget"
                        type="text"
                        placeholder="Enter Task Budget..."
                        class="form-control"
                      />
                    </div>
                  </div> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
